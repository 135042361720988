import React, { useState } from 'react'
import "./Galery.css"
import {photoData} from "../../scripts/photoData.js"
import Section from '../Section/Section.jsx'
import PreviewImg from '../PreviewImg/PreviewImg.jsx'

const Galery = ({t}) => {

    const photos = photoData

    const [ToggleImg, setToggleImg] = useState ("false")

    const [idImg, setIdImg] = useState ("")



    const handleToggle = (event) => {
    
        setToggleImg(!ToggleImg)

        setIdImg(event.target.id - 1)
    }



    const photoList = photos.map((photo) =>{
        return <div 
                    onClick={handleToggle} 
                    className='GaleryImgContainer' 
                    key={photo.id}>
                        
                        <img id={photo.id} 
                            className='GaleryImg' 
                            src={photo.src} 
                            alt="a" 
                        />
                            
                </div>

    })

    const photoList2 = photos.map((photo) =>{
        return <div 
                    onClick={handleToggle} 
                    className='GaleryWallImgContainer' 
                    key={photo.id}>
                        
                        <img id={photo.id} 
                            className='GaleryWallImg' 
                            src={photo.src} 
                            alt="a" 
                        />
                            
                </div>

    })


  return (
     
    <div>
        <Section name={t("section.galery")}/>
        
        <div className={ToggleImg ? 'GaleryContainer' : undefined}>

            <div className={ToggleImg ? 'Galery' : undefined}>{photoList}</div>
            
            
            
        </div>

        <div className='GaleryWallContainer'>
            <div className='GaleryWall'>{photoList2}</div>
        </div>
            
        {ToggleImg ? undefined : <PreviewImg handleToggle={handleToggle} photos={photos} idImg={idImg}/>}
    </div>
  )
}

export default Galery