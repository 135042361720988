

export const photoData = [
    {
        id: 1,
        src: "https://i.ibb.co/J5tn4dk/1.jpg"
    },
    {
        id: 2,
        src: "https://i.ibb.co/rfWGTzj/2.jpg"
    },
    {
        id: 3,
        src: "https://i.ibb.co/pxfy2dH/3.jpg"
    },
    {
        id: 4,
        src: "https://i.ibb.co/5BgBq9X/4.jpg"
    },
    {
        id: 5,
        src: "https://i.ibb.co/tMQYpQs/5.jpg"
    },
    {
        id: 6,
        src: "https://i.ibb.co/pJTqm1R/6.jpg"
    },
    {
        id: 7,
        src: "https://i.ibb.co/kX3nJXh/7.jpg"
    },
    {
        id: 8,
        src: "https://i.ibb.co/jgRmpPR/8.jpg"
    },
    {
        id: 9,
        src: "https://i.ibb.co/JR07wns/9.jpg"
    },
    {
        id: 10,
        src: "https://i.ibb.co/xCrccfK/10.jpg"
    },
    {
        id: 11,
        src: "https://i.ibb.co/bXCfZvH/11.jpg"
    },
    {
        id: 12,
        src: "https://i.ibb.co/dP8PhrZ/12.jpg"
    },
    {
        id: 13,
        src: "https://i.ibb.co/T4JJJB1/img1.jpg"
    },
    {
        id: 14,
        src: "https://i.ibb.co/myTdjHY/13.jpg"
    },
    {
        id: 15,
        src: "https://i.ibb.co/47s996h/14.jpg"
    },
    {
        id: 16,
        src: "https://i.ibb.co/N2NJ4MC/15.jpg"
    },
    {
        id: 17,
        src: "https://i.ibb.co/8rmJ51n/16.jpg"
    },
    {
        id: 18,
        src: "https://i.ibb.co/vx0PGM8/17.jpg"
    },
    {
        id: 19,
        src: "https://i.ibb.co/PjTZ6tg/18.jpg"
    },
    {
        id: 20,
        src: "https://i.ibb.co/gSNDnmZ/19.jpg"
    },
    {
        id: 21,
        src: "https://i.ibb.co/YQRFBQ0/20.jpg"
    },
    {
        id: 22,
        src: "https://i.ibb.co/3MF64mD/21.jpg"
    },
    {
        id: 23,
        src: "https://i.ibb.co/3YM2mwS/22.jpg"
    },
    {
        id: 24,
        src: "https://i.ibb.co/jy41TyT/23.jpg"
    },];