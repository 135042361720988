import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';



import './App.css';

import BackgroundAudio from '../src/assets/backgroundMusic.mp3'

import Home from './components/Home/Home';
import Banner from './components/Banner/Banner';
import Reservas from './components/CasaArmonia/Reservas';
import Contacto from './components/Contacto/Contacto';
import Galery from './components/Galery/Galery';
import SierrasDeValdivia from './components/SierrasDeValdivia/SierrasDeValdivia';

import mpLogo from "../src/assets/img/mp.jpg"
import NavBar from './components/NavBar/NavBar';
import WhatsappButton from './components/WhatsappButton/WhatsappButton';
import Cliengo from './components/Cliengo/Cliengo';





function App() {

  const [t, i18n] = useTranslation("global")
  
  const [isActive, setActive] = useState("false");
  
  const [HomeActive, setHomeActive] = useState("true")
  
    
  const ToggleHomeActive = () => {
    let audio = new Audio(BackgroundAudio)  
    setHomeActive(!HomeActive)
    audio.play()
    
  }
  
  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang)
  }

  const ToggleClass = () => {
    setActive(!isActive);
  };


  
  const redirectWhatsapp = () => {window.location.href="https://api.whatsapp.com/send?phone=59897259227"}
  const redirectEmail = () => {window.location.href="mailto:info@casaarmonia.com.uy"}
  const redirectInstagram = () => {window.location.href="https://www.instagram.com/casa.armonia.uruguay"}
  const redirectMaps = () => {window.location.href="https://maps.app.goo.gl/8UaCJo25m7FdMf3c6"}

  
  
  
  
  return (
    <div>

 
      
      
    <div className={HomeActive ? "homeInactive" : "homeActive"}>

      
      <WhatsappButton redirectWhatsapp={redirectWhatsapp}/>
  

      <NavBar isActive={isActive} ToggleClass={ToggleClass} handleChangeLanguage={handleChangeLanguage} t={t}/> 

      {/* /////////////////////  APP COMPONENTS /////////////////////////////////////////////////////////////////////////////////////// */}
          <Banner t={t}/>

          <Cliengo/>

          <div id='galeria'><Galery t={t}/></div>

          <div id='sierras'><SierrasDeValdivia t={t}/></div>

          <div id='reservas'><Reservas t={t} redirectWhatsapp={redirectWhatsapp}/></div>
            
          <div id='contacto'><Contacto t={t} redirectWhatsapp={redirectWhatsapp}/></div>
    
          
          
          
          
      {/* /////////////////////  FOOTER /////////////////////////////////////////////////////////////////////////////////////// */}
          
          
                    
          <div className='FooterContainer'>
          
              <div className="FooterMenu">
                  <ul className='FooterMenuList'>
                      <li onClick={() => window.location.replace("#inicio")} className='FooterMenuListItem DarkText'>{t("navbar.home")}</li>
                      <li onClick={() => window.location.replace("#galeria")} className='FooterMenuListItem DarkText'>{t("navbar.galery")}</li>
                      <li onClick={() => window.location.replace("#sierras")} className='FooterMenuListItem DarkText'>Sierras de Valdivia</li>
                      <li onClick={redirectWhatsapp} className='FooterMenuListItem DarkText'>{t("navbar.booking")}</li>
                  </ul>
              </div>
  
              <div className="FooterSocial">
                  <ul className='FooterSocialList'>
                      <li onClick={redirectWhatsapp} className="FooterSocialListItem DarkText">+598 97 259 227</li>
                      <li onClick={redirectEmail} className="FooterSocialListItem DarkText">info@casaarmonia.com.uy</li>
                      <li onClick={redirectInstagram} className="FooterSocialListItem DarkText">@casaarmonia</li>
                  </ul>
              </div>

              <div className='FooterMp'>
                <img className='FooterMpIcon' src={mpLogo} alt="mercadopago" />
                <span className='FooterMpText'>{t("footer.mp")}</span>
              </div>
          
              
          
              <div className="FooterText">
                  <span>Todos los derechos reservados, Casa Armonía.</span>
              </div>


          </div>
      
      
       
      
      </div>

      {HomeActive ? <Home ToggleHomeActive={ToggleHomeActive} className={HomeActive ? "homeActive" : "homeInactive"}/> : undefined}
    </div>
  );
}

export default App;
